<template>
  <fw-layout management :back-to="`/manage/courses`" :notfound="!loading && !course">
    <template #main-content>
      <fw-panel :title="course?.title ?? ''" subtitle="Certificados de formação / Curso" featured>
        <template #after-heading>
          <fw-menu-more>
            <fw-button
              type="basic-action"
              size="sm"
              label="Editar"
              custom-class="w-full"
              @click.native="isModalActive = true"
            >
              Editar título
            </fw-button>
            <fw-button
              v-if="validations?.can_delete"
              type="basic-action"
              size="sm"
              custom-class="w-full"
              label="Eliminar"
              @click.native="deleteCourse"
            >
              Remover curso
            </fw-button>
          </fw-menu-more>
        </template>
        <template #default>
          <PanelManageCourseEditions ref="editionsList" :course-key="courseKey" @new-edition="openAddEditionModal" />
        </template>
      </fw-panel>
    </template>

    <template #modals>
      <fw-modal :active.sync="isModalActive" :can-cancel="true" size="auto" boxed="sm" @close="closeModal">
        <ModalManageCourseMetadata
          :saving-data="savingCourseData"
          :can-edit="true"
          :course="course"
          @save="saveCourse"
          @close="closeModal"
        />
      </fw-modal>
      <fw-modal :active.sync="isEditionModalActive" :can-cancel="true" size="auto" boxed="sm" @close="closeModal">
        <ModalManageCourseEditionMetadata
          :saving-data="savingData"
          :can-edit="true"
          :course="course"
          @save="addEdition"
          @close="closeModal"
        />
      </fw-modal>
    </template>
  </fw-layout>
</template>

<script>
import PanelManageCourseEditions from '@/components/panels/manage/PanelManageCourseEditions'
import ModalManageCourseMetadata from '@/components/modals/ModalManageCourseMetadata'
import ModalManageCourseEditionMetadata from '@/components/modals/ModalManageCourseEditionMetadata'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    PanelManageCourseEditions,
    ModalManageCourseMetadata,
    ModalManageCourseEditionMetadata
  },

  data() {
    return {
      isModalActive: false,
      isEditionModalActive: false,
      loading: true,
      course: null,
      savingCourseData: false,
      savingData: false,
      validations: {
        can_delete: false
      }
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    courseKey() {
      return this.$route.params.key
    },

    permissions() {
      return this.$store.getters.userPermissions
    }
  },

  mounted() {
    this.getCourse()
  },

  methods: {
    closeModal() {
      this.isModalActive = false
      this.isEditionModalActive = false
    },

    openAddModal() {
      this.openModal({
        title: null
      })
    },

    openModal() {
      this.isModalActive = true
    },

    openAddEditionModal() {
      this.isEditionModalActive = true
    },

    saveCourse(data) {
      this.updateCourse(data)
      this.closeModal()
    },

    async getCourse() {
      this.loading = true

      await utils.tryAndCatch(this, async () => {
        const response = await this.api.getManagerCourse(this.courseKey)
        console.log('getManagerCourse :>> ', response)
        this.course = response.course
        this.validations = response.validations
      })
      this.loading = false
    },

    async addEdition(data) {
      this.savingData = true

      await utils.tryAndCatch(this, async () => {
        const response = await this.api.addManagerEditions(this.courseKey, data)
        console.log('addManagerCourses :>> ', response)
        this.closeModal()
        this.$refs.editionsList.getEditions()
      })
      this.savingData = false
    },

    async updateCourse(data) {
      this.savingCourseData = true

      await utils.tryAndCatch(this, async () => {
        const response = await this.api.editManagerCourse(this.course.key, data)
        console.log('editManagerCourse :>> ', response)
        this.course = response.course
        this.validations = response.validations
      })

      this.savingCourseData = false
    },

    comfirmDelete() {
      this.$buefy.dialog.confirm({
        confirmText: 'Eliminar',
        type: 'is-danger',
        cancelText: 'Cancelar',
        title: 'Eliminar curso',
        message: `<div class="has-margin-bottom-small">Tem a certeza que deseja <strong>eliminar</strong> este curso?
        Todos os dados serão eliminados e não será possível recuperar qualquer informação.</div>`,
        onConfirm: () => {
          this.deleteCourse(this.course.key)
        }
      })
    },

    async deleteCourse() {
      this.savingCourseData = true

      await utils.tryAndCatch(this, async () => {
        const response = await this.api.deleteManagerCourse(this.course.key)
        console.log('deleteManagerCourse :>> ', response)
        this.$router.push({ name: 'manage-courses' })
      })

      this.savingCourseData = false
    }
  }
}
</script>
